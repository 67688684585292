<template>
  <div class="monitoring-page">
    <div class="columns mt-3">
      <div class="column">
        <div
          class="monitoring-box box is-shadowless has-text-centered"
          @click="filterByLevel('call')"
        >
          <h1 class="is-size-5">
            Total applications calls
          </h1>
          <h1 class="is-size-3">
            {{ totals.calls }}
          </h1>
        </div>
      </div>
      <div class="column">
        <div
          class="monitoring-box box is-shadowless has-text-centered"
          @click="filterByLevel('error')"
        >
          <h1 class="is-size-5">
            Total errors
          </h1>
          <h1 class="is-size-3">
            {{ totals.errors }}
          </h1>
        </div>
      </div>
      <div class="column">
        <div
          class="monitoring-box box is-shadowless has-text-centered"
          @click="filterByLevel('info')"
        >
          <h1 class="is-size-5">
            Total info messages
          </h1>
          <h1 class="is-size-3">
            {{ totals.info }}
          </h1>
        </div>
      </div>
      <div class="column">
        <div class="box is-shadowless has-text-centered">
          <h1 class="is-size-5">
            Average latency in MS
          </h1>
          <h1 class="is-size-3">
            {{ totals.latency }}
          </h1>
        </div>
      </div>
    </div>

    <div class="my-4">
      <b-field
        label="Period"
        class="mr-4 is-inline-block"
      >
        <b-select
          v-model="period"
          size="is-small"
          @input="fetchLogs"
        >
          <option value="last-24-hour">
            Last 24 hours
          </option>
          <option value="last-7-day">
            Last 7 days
          </option>
          <option value="last-30-day">
            Last 30 days
          </option>
          <option value="custom">
            Custom range
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Environment"
        class="mr-4 is-inline-block"
      >
        <b-select
          v-model="environment"
          size="is-small"
          @input="fetchLogs"
        >
          <option :value="null">
            All
          </option>
          <option value="production">
            Production
          </option>
          <option value="test">
            Test
          </option>
        </b-select>
      </b-field>

      <b-field
        v-if="period === 'custom'"
        label="Select time from"
        class="mr-4 is-inline-block"
      >
        <b-tooltip
          label="inclusive"
          append-to-body
        >
          <b-datetimepicker
            v-model="range.from"
            class="custom-range-date-picker"
            size="is-small"
            placeholder="Click to select..."
            locale="en-US"
            :date-parser="parseDate"
            @input="fetchLogs"
          />
        </b-tooltip>
      </b-field>

      <b-field
        v-if="period === 'custom'"
        label="Select time to"
        class="mr-4 is-inline-block"
      >
        <b-tooltip
          label="exclusive(minute only)"
          append-to-body
        >
          <b-datetimepicker
            v-model="range.to"
            class="custom-range-date-picker"
            size="is-small"
            placeholder="Click to select..."
            locale="en-US"
            @input="fetchLogs"
          />
        </b-tooltip>
      </b-field>

      <b-field
        label="Application"
        class="mr-4 is-inline-block"
      >
        <b-select
          v-model="selectedApplication"
          size="is-small"
          @input="selectedModule = null; fetchModules(); fetchLogs()"
        >
          <option :value="null">
            All applications
          </option>

          <option
            v-for="(application, index) in applications"
            :key="index"
            :value="application.id"
          >
            {{ application.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        v-if="selectedApplication"
        label="Module"
        class="mr-4 is-inline-block"
      >
        <b-select
          v-model="selectedModule"
          size="is-small"
          @input="fetchLogs"
        >
          <option :value="null">
            All modules
          </option>
          <option
            v-for="(module, index) in modules"
            :key="index"
            :value="module.id"
          >
            {{ module.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Level"
        class="mr-4 is-inline-block"
      >
        <b-select
          v-model="selectedLevel"
          size="is-small"
          @input="fetchLogs"
        >
          <option
            :value="null"
          >
            All levels
          </option>

          <option value="call">
            Call
          </option>
          <option value="info">
            Info
          </option>
          <option value="error">
            Error
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Session"
        expanded
        class="mr-4 is-inline-block suggestions-input"
      >
        <b-autocomplete
          v-model="sessionId"
          clearable
          size="is-small"
          :data="sessionSuggestions.options"
          :loading="sessionSuggestions.isLoading"
          @select="updateSessionId"
        />
      </b-field>
      
      <b-field
        label="Search"
        class="mr-4 is-inline-block"
        expanded
        style="width: 300px"
      >
        <b-input
          v-model="fuzzySearch"
          size="is-small"
          @input="callDebounce"
        />
      </b-field>
    </div>
    <b-loading
      v-model="isLogFetching"
      :is-full-page="true"
      :can-cancel="false"
    />
    <b-table
      :data="logs"
      detailed
      detail-key="id"
      :show-detail-icon="true"
      class="tab-table-layout"
      paginated
      backend-pagination
      :total="totals.total"
      :per-page="limit"
      @page-change="onPageChange"
    >
      <b-table-column
        v-slot="{ row }"
        field="type"
        label="Level"
      >
        <b-tag :type="row.type === 'INFO' ? 'is-info' : row.type === 'ERROR' ? 'is-danger' : 'is-success'">
          {{ row.type }}
        </b-tag>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        field="session_id"
        label="Session"
      >
        {{ row.session_id }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        field="created_at"
        label="Time"
      >
        {{ row.created_at }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        field="title"
        label="Title"
      >
        {{ row.title }}
      </b-table-column>

      <template #detail="{ row }">
        <h1 class="is-size-6 mb-2">
          Message
        </h1>
        <code class="details">
          {{ row.message }}
        </code>
      </template>
    </b-table>
  </div>
</template>

<script >
import { useBuefy } from '@/hooks/buefy';
import { logMonitoringService, fetchSessionSuggestionsFromLogs } from '@/services/monitoring-service/monitoringVariablesRequests';
import { onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import moment from 'moment';
import { fetchApplicationsService } from '@/services/application-service/applicationRequests';
import { fetchModulesService } from '@/services/application-service/moduleRequests';
import lodash from 'lodash';
import { useRouter, useRoute } from '@/hooks/vueRouter';
import { convertUTCToLocalTime } from '@/helpers/util';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  const buefy = useBuefy();
  const isLogFetching = ref(false);
  const logs = ref([]);
  const totals = ref({
    calls: 0,
    errors: 0,
    info: 0,
    latency: 0
  });
  const sessionSuggestions = ref({
    isLoading: false,
    options: []
  });
  const selectedApplication = ref(route.query.application || null);
  const selectedModule = ref(route.query.module || null);
  const selectedLevel = ref(route.query.level || null);
  const fuzzySearch = ref(route.query.fuzzy || null);
  const applications = ref([]);
  const modules = ref([]);
  const period = ref(route.query.period || 'last-24-hour');
  const page = ref(route.query.page || 1);
  const limit = ref(10);
  const environment = ref(route.query.environment || 'production');
  const sessionId = ref(route.query.session_id || '');
  const range = ref({
    from: moment().subtract(1, 'days').toDate(),
    to: moment().toDate()
  });
  const fetchApplications = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchApplicationsService({
        sort: 'newest'
      });
      applications.value = data.applications.map(application => ({
        id: application.id,
        name: application.name
      }));
    } catch (err) {
      console.log(err);
    }
  };
  const populateURL = () => {
    let query = {};
    if (selectedApplication.value) {
      query.application = selectedApplication.value;
    }
    if (selectedModule.value) {
      query.module = selectedModule.value;
    }
    if (selectedLevel.value) {
      query.level = selectedLevel.value;
    }
    if (period.value) {
      query.period = period.value;
    }
    if (environment.value) {
      query.environment = environment.value;
    }
    if (fuzzySearch.value) {
      query.fuzzy = fuzzySearch.value;
    }
    if (page.value) {
      query.page = page.value;
    }
    if (sessionId.value) {
      query.session_id = sessionId.value;
    }
    router.push({
      query: query
    });
  };
  const fetchSessionSuggestions = async () => {
    try {
      sessionSuggestions.value.isLoading = true;
      sessionSuggestions.value.options = [];
      if (sessionId.value?.length >= 4) {
        const {
          data: {
            data
          }
        } = await fetchSessionSuggestionsFromLogs({
          sessionId: sessionId.value
        });
        sessionSuggestions.value.options = data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      sessionSuggestions.value.isLoading = false;
    }
  };
  debouncedWatch(() => sessionId.value, () => {
    fetchSessionSuggestions();
  }, {
    debounce: 1000
  });
  const updateSessionId = newSessionId => {
    sessionId.value = newSessionId;
    fetchLogs();
  };
  const fetchLogs = async () => {
    isLogFetching.value = true;
    populateURL();
    try {
      const {
        data: {
          data
        }
      } = await logMonitoringService({
        application_id: selectedApplication.value,
        module_id: selectedModule.value,
        level: selectedLevel.value,
        period: period.value,
        from: range.value.from,
        to: range.value.to,
        environment: environment.value,
        session_id: sessionId.value,
        fuzzy_search: fuzzySearch.value ? fuzzySearch.value.trim() : fuzzySearch.value,
        page: page.value,
        limit: limit.value
      });
      totals.value = {
        calls: data.call_total,
        errors: data.error_total,
        info: data.info_total,
        latency: data.latency_average,
        total: data.total
      };
      logs.value = data.logs.map(log => ({
        id: log.id,
        created_at: convertUTCToLocalTime(log.created_at),
        session_id: log.session_id,
        type: log.type.toUpperCase(),
        title: log.title,
        message: log.message
      }));
    } catch (err) {
      console.error(err);
      buefy.toast.open({
        duration: 25000,
        message: 'Request timeout, please narrow down your search date range.',
        position: 'is-top',
        type: 'is-danger'
      });
    } finally {
      isLogFetching.value = false;
    }
  };
  const onPageChange = async newPage => {
    page.value = newPage;
    router.replace({
      path: route.path,
      query: {
        ...route.query,
        page: newPage
      }
    });
    fetchLogs();
  };
  const fetchModules = async () => {
    const {
      data: {
        data
      }
    } = await fetchModulesService(selectedApplication.value);
    modules.value = data.map(module => ({
      id: module.id,
      name: module.name
    }));
  };
  const parseDate = date => {
    console.log(date);
  };
  const filterByLevel = level => {
    selectedLevel.value = level;
    fetchLogs();
  };
  const callDebounce = lodash.debounce(() => {
    fetchLogs();
  }, 500);
  onMounted(() => {
    fetchApplications();
    if (selectedApplication.value) {
      fetchModules();
    }
    fetchLogs();
  });
  return {
    isLogFetching,
    logs,
    totals,
    sessionSuggestions,
    selectedApplication,
    selectedModule,
    selectedLevel,
    fuzzySearch,
    applications,
    modules,
    period,
    limit,
    environment,
    sessionId,
    range,
    updateSessionId,
    fetchLogs,
    onPageChange,
    fetchModules,
    parseDate,
    filterByLevel,
    callDebounce
  };
};
export default __sfc_main;
</script>

<style lang="scss" >
@import '~@/style/components.scss';

.monitoring-page {
  .b-table {
    padding-bottom: 40px!important;
  }
  .select {
    &.is-empty {
      select {
        color: #4a4a4a;
      } 
    }
  }
  .monitoring-box {
    &:hover {
      background: rgba(196, 196, 196, 0.1);
      cursor: pointer;
    }
  }
  .d2h-wrapper {
    .d2h-files-diff {
      .d2h-file-side-diff {
        position: static !important;
      }
      .d2h-diff-tbody {
        tr {
          td {
            padding: 0!important;
          }
        }
      }
    }
  }
  .detail-container {
    .details {
      overflow-wrap: anywhere;
      overflow: hidden;
      display: block;
    }
  }
  .suggestions-input {
    width: 19rem;
    .dropdown-menu {
      .dropdown-item {
        padding-right: 1rem;
      }
    }
  }
  .custom-range-date-picker {
    font-size: 1rem;
  }
}
</style>

<template>
  <div>
    <teleport selector="#appButton">
      <b-button
        class="px-6 add-new-btn btn-primary-light"
        @click="openNewTableModal"
      >
        <b-icon
          icon="plus"
          size="is-small"
        />New table
      </b-button>
    </teleport>

    <div class="columns">
      <div class="column is-12">
        <div
          v-if="!isTableFetchDone"
          class="columns is-vcentered px-5"
        >
          <div class="is-10 column">
            <b-skeleton height="30" />
            <b-skeleton height="50" />
          </div>
          <div class="is-2 column">
            <b-skeleton height="30" />
          </div>
        </div>

        <NoResults
          v-else-if="tables.length === 0"
          banner-label="No tables yet, create one now."
          button-label="New Table"
          :button-handler="openNewTableModal"
        />

        <b-table
          v-else
          :data="tables"
          class="tab-table-layout"
          @click="rowClicked($event)"
        >
          <b-table-column
            v-slot="{ row }"
            label="Name"
            field="name"
          >
            {{ row.TABLE_NAME }}
          </b-table-column>
          <b-table-column
            v-slot="{ row }"
            label="Created at"
            field="created_at"
          >
            {{ row.CREATE_TIME }}
          </b-table-column>
          <b-table-column
            v-slot="{ row }"
            label="Last modified at"
            field="modified_at"
          >
            {{ row.UPDATE_TIME }}
          </b-table-column>
          <b-table-column
            v-slot="{ row }"
            label="Number of records"
            field="number_of_records"
          >
            {{ row.TABLE_ROWS }}
          </b-table-column>
          <b-table-column
            v-slot="{ row }"
            label=""
            cell-class="has-text-right"
          >
            <b-button
              size="is-small"
              class="app-dropdown-btn"
              @click.stop="toggleActionDropdown(row.TABLE_NAME)"
            >
              <b-icon
                icon="dots-horizontal"
                size="is-size-6"
                class="app-dropdown-icon"
              />
            </b-button>
            <b-dropdown
              :ref="`moduleActionDropdown${row.TABLE_NAME}`"
              aria-role="list"
              position="is-bottom-left"
              class="app-action-dropdown"
              append-to-body
            >
              <!-- <b-dropdown-item
                aria-role="listitem"
                class="is-flex is-align-items-center"
                @click="editTable(row)"
              >
                <b-icon
                  icon="pencil-outline"
                  type="is-black"
                  custom-size="mdi-22px"
                  class="mr-2"
                />
                Edit
              </b-dropdown-item> -->
              <b-dropdown-item
                aria-role="listitem"
                class="is-flex is-align-items-center"
                @click="goToStructure(row.TABLE_NAME)"
              >
                <b-icon
                  icon="eye"
                  type="is-black"
                  custom-size="mdi-22px"
                  class="mr-2"
                />
                Structure
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                class="is-flex is-align-items-center"
                @click="goToData(row.TABLE_NAME)"
              >
                <b-icon
                  icon="content-copy"
                  type="is-black"
                  custom-size="mdi-22px"
                  class="mr-2"
                />
                Data
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                custom
                class="is-flex is-align-items-center is-justify-content-center"
              >
                <b-button
                  icon-left="delete-outline"
                  size="is-small"
                  type="is-danger is-light"
                  class="rounded-8 w-full"
                  @click.stop="confirmDelete(row.TABLE_NAME)"
                >
                  Delete
                </b-button>
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <BaseModal
      v-model="newTableModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      :width="1000"
      aria-role="dialog"
      aria-label="New table"
      aria-modal
    >
      <CardPopup
        title="New table"
        class="w-1000"
        @hide="newTableModalActive = false"
      >
        <template #body>
          <b-field
            label="Table name"
            class="mb-4"
            :type="{ 'is-danger': isEmptyTableName }"
            :message="[
              { 'Table name missing': isEmptyTableName }
            ]"
          >
            <b-input
              v-model="newTable.name"
              type="text"
              placeholder="e.g. My "
              required
              @input="changeTableName($event)"
            />
          </b-field>
          <label class="label">Table columns</label>

          <div
            v-for="(column, index) in newTable.columns"
            :key="index"
            class="columns"
          >
            <div class="column is-5">
              <b-input
                v-model="column.column_name"
                type="text"
                :disabled="index === 0"
                expanded
                required
                size="is-small"
              />
            </div>
            <div class="column is-3">
              <b-select
                v-model="column.column_type"
                type="text"
                :disabled="index === 0"
                expanded
                required
                size="is-small"
              >
                <option
                  v-for="(type, typeIdx) in options.types"
                  :key="typeIdx"
                >
                  {{ type }}
                </option>
              </b-select>
            </div>
            <div class="column is-1">
              <b-button
                type="is-danger"
                :disabled="index === 0"
                size="is-small"
                @click="removeColumn(index)"
              >
                <b-icon icon="trash-can-outline" />
              </b-button>
            </div>
          </div>
          <b-button
            type="is-primary"
            size="is-small"
            @click="addColumn()"
          >
            <b-icon
              icon="plus"
              size="is-small"
              class="mr-2"
            />Add column
          </b-button>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="newTableModalActive = false, isEmptyTableName = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="createTable()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
    <CardPopup
      v-if="isEditTableModalVisible === true"
      title="Edit Table"
      @hide="isEditTableModalVisible = false"
    >
      <template #body>
        <b-field
          label="Table name*"
        >
          <b-input
            v-model="tableToBeEdited.name"
            type="text"
            placeholder="e.g. My awesome table"
          />
        </b-field>
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="isEditTableModalVisible = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-primary"
            class="px-6 rounded-8"
            @click="updateTable()"
          >
            Update
          </b-button>
        </div>
      </template>
    </CardPopup>
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref, getCurrentInstance, reactive, watch } from '@vue/composition-api';
import { useBuefy } from '@/hooks/buefy';
import { reactiveResetter } from '@/hooks/utils';
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { fetchDatabaseByIdService } from '@/services/database-service/databaseRequests';
import { createTableService, updateTableService, deleteTableService, fetchTablesService } from '@/services/database-service/tableRequests';

// @VUE3: do not use this function when migrating to vue 3

//-- child components --//
const NoResults = defineAsyncComponent(() => import('@/modules/core/components/NoResults.vue'));
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));

//-- composable hooks --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const vm = getCurrentInstance();
  const route = useRoute();
  const router = useRouter();
  const buefy = useBuefy();

  //-- database logic --//
  const databaseName = ref('');
  const fetchDatabase = async () => {
    try {
      const response = await fetchDatabaseByIdService(route.params.databaseId);
      databaseName.value = response.data.data.name;
      await fetchTables();
    } catch (err) {
      console.error(err);
    }
  };
  onMounted(() => {
    fetchDatabase();
  });

  //-- fetch table logic --//
  const tables = ref([]);
  const isTableFetchDone = ref(false);
  const fetchTables = async () => {
    try {
      const response = await fetchTablesService(route.params.databaseId);
      tables.value = response.data.data;
      isTableFetchDone.value = true;
    } catch (err) {
      console.error(err);
    }
  };
  // Edit Table
  const isEditTableModalVisible = ref(false);
  const tableToBeEdited = reactive({
    id: '',
    name: ''
  });
  const editTable = item => {
    tableToBeEdited.id = item.TABLE_NAME;
    tableToBeEdited.name = item.TABLE_NAME;
    isEditTableModalVisible.value = true;
  };
  const updateTable = async () => {
    const isAlredyExists = tables.value.find(({
      TABLE_NAME
    }) => TABLE_NAME === tableToBeEdited.name);
    if (!isAlredyExists && tableToBeEdited.id != tableToBeEdited.name) {
      await updateTableService(route.params.databaseId, tableToBeEdited.id, {
        name: tableToBeEdited.name
      });
      const editedTableRow = tables.value.find(t => t.TABLE_NAME === tableToBeEdited.id);
      if (editedTableRow) {
        editedTableRow.TABLE_NAME = tableToBeEdited.name;
      }
      buefy.toast.open('Table renamed!');
    }
    isEditTableModalVisible.value = false;
  };
  // -- Edit table -- //

  //-- create table logic --//
  const newTableModalActive = ref(false);
  const isEmptyTableName = ref(false);
  const [newTable, resetNewTable] = reactiveResetter({
    name: '',
    columns: [{
      column_name: 'id',
      column_type: 'UNIQUE',
      default_value: 'UNIQUE VALUE'
    }, {
      column_name: '',
      column_type: '',
      default_value: ''
    }]
  });
  watch(() => newTableModalActive.value, () => {
    if (!newTableModalActive.value) {
      resetNewTable();
    }
  });
  const openNewTableModal = () => {
    newTableModalActive.value = true;
  };
  const createTable = async () => {
    try {
      if (newTable.name.trim() === '') {
        isEmptyTableName.value = true;
        return false;
      }
      const response = await createTableService(route.params.databaseId, {
        name: newTable.name,
        columns: newTable.columns
      });
      newTableModalActive.value = false;
      await router.push('/application/' + route.params.appId + '/database/' + route.params.databaseId + '/table/' + response.data.data.name + '/data');
    } catch (err) {
      console.error(err);
    }
  };
  const addColumn = () => {
    newTable.columns.push({
      column_name: '',
      column_type: '',
      default_value: ''
    });
  };
  /**
   * @param {number} index 
   */
  const removeColumn = index => {
    newTable.columns.splice(index, 1);
  };

  //-- delete table logic --//
  /**
   * @param {string} id 
   */
  const confirmDelete = id => {
    buefy.dialog.confirm({
      title: 'Deleting table',
      message: 'Are you sure you want to <b>delete</b> this table? This action cannot be undone.',
      confirmText: 'Delete table',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        deleteTable(id);
        buefy.toast.open('Table deleted!');
        fetchTables();
      }
    });
  };
  /**
   * @param {sting} id 
   */
  const deleteTable = async id => {
    try {
      await deleteTableService(route.params.databaseId, id);
      await fetchTables();
    } catch (err) {
      console.error(err);
    }
  };

  //-- table actions logic --//
  const options = reactive({
    types: ['UNIQUE', 'WHOLE NUMBER / INTEGER', 'DECIMAL NUMBER / FLOAT', 'PASSWORD', 'TEXT', 'DATE', 'DATETIME'],
    defaultValues: {
      datetime: ['CURRENT TIME'],
      date: ['CURRENT DATE'],
      unique: ['UNIQUE VALUE']
    }
  });
  /**
   * @param {string} rowId 
   */
  const toggleActionDropdown = rowId => {
    vm.proxy.$refs[`moduleActionDropdown${rowId}`].toggle();
  };
  /**
   * @param {sting} id 
   */
  const goToStructure = id => {
    router.push('/application/' + route.params.appId + '/database/' + route.params.databaseId + '/table/' + id + '/structure');
  };
  /**
   * @param {sting} id 
   */
  const goToData = id => {
    router.push('/application/' + route.params.appId + '/database/' + route.params.databaseId + '/table/' + id + '/data');
  };
  const rowClicked = eventPayload => {
    goToData(eventPayload.TABLE_NAME);
  };
  const changeTableName = event => {
    if (event) {
      isEmptyTableName.value = false;
    }
  };
  return {
    tables,
    isTableFetchDone,
    isEditTableModalVisible,
    tableToBeEdited,
    updateTable,
    newTableModalActive,
    isEmptyTableName,
    newTable,
    openNewTableModal,
    createTable,
    addColumn,
    removeColumn,
    confirmDelete,
    options,
    toggleActionDropdown,
    goToStructure,
    goToData,
    rowClicked,
    changeTableName
  };
};
__sfc_main.components = Object.assign({
  NoResults,
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.list:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '~@/style/components.scss';
</style>
<template>
  <div>
    <teleport selector="#appButton">
      <b-button
        class="px-6 add-new-btn btn-primary-light top-btn"
        @click="openDatabaseModal"
      >
        <b-icon
          icon="plus"
          size="is-small"
        />New database
      </b-button>
    </teleport>
    <AppDatabases />

    <BaseModal
      v-model="databaseModalActivity"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Database"
      aria-modal
    >
      <CardPopup
        title="Database"
        class="w-800"
        @hide="databaseModalActivity = false"
      >
        <template #body>
          <div class="columns">
            <div
              class="card-icon-options card p-3 mb-3 column is-5 mx-5 module-type is-shadowless"
              @click="openNewDatabaseModal"
            >
              <div class="columns">
                <div class="column is-3">
                  <b-icon
                    size="is-medium"
                    icon="database-plus"
                  />
                </div>
                <div class="column is-9 is-vertical is-mobile">
                  <p class="is-size-4">
                    New database
                  </p>
                </div>
              </div>
            </div>
            <div
              class="card-icon-options card p-3 mb-3 column is-5 mx-5 module-type is-shadowless"
              @click="openImportDatabaseModal"
            >
              <div class="columns">
                <div class="column is-3">
                  <b-icon
                    size="is-medium"
                    icon="database-arrow-up"
                  />
                </div>
                <div class="column is-9 is-vertical is-mobile">
                  <p class="is-size-4">
                    Import database
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="databaseModalActivity = false"
            >
              Cancel
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>

    <BaseModal
      v-model="newDatabaseModalActivity"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New database"
      aria-modal
    >
      <CardPopup
        title="New database"
        class="w-800"
        @hide="newDatabaseModalActivity = false"
      >
        <template #body>
          <b-field label="Database name*"
          :type="databaseErrors.name? 'is-danger' : ''"
          :message="databaseErrors.name? databaseErrors.name: ''"
          >
            <b-input
              v-model="database.name"
              type="text"
              placeholder="e.g. My secure database"
              @keyup.native="validateDatabase('name')"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="newDatabaseModalActivity = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="createDatabase()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>

    <BaseModal
      v-model="importDatabaseModalActivity"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Import database"
      aria-modal
    >
      <CardPopup
        title="Import database"
        class="w-800"
        :show-footer="false"
        @hide="importDatabaseModalActivity = false"
      >
        <template #body>
          <b-steps
            v-model="activeStep"
            :animated="true"
            :rounded="true"
            :has-navigation="false"
            icon-prev="chevron-left"
            icon-next="chevron-right"
            label-position="bottom"
            mobile-mode="minimalist"
          >
            <b-step-item
              step="1"
              label="File"
              :clickable="false"
            >
              <div class="has-text-centered">
                <h3 class="subtitle has-text-centered">
                  Upload excel file
                </h3>
                <b-upload
                  v-model="excelFile"
                  drag-drop
                  @input="parse"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                          icon="upload"
                          size="is-large"
                        />
                      </p>
                      <p>Drop your file here or click to upload</p>
                      <h1 class="title">
                        {{ excelFile.name }}
                      </h1>
                    </div>
                  </section>
                </b-upload>
                <b-button
                  class="my-3 add-new-btn btn-primary-light"
                  expanded
                  @click="activeStep++"
                >
                  Start the import
                </b-button>
              </div>
            </b-step-item>
            <b-step-item
              step="2"
              label="Database"
              :clickable="false"
            >
              <h1 class="subtitle has-text-centered">
                Check database name
              </h1>
              <b-field label="Database name">
                <b-input
                  v-model="importedDatabase.name"
                  type="text"
                />
              </b-field>
              <div class="columns">
                <div class="column is-4">
                  <b-button
                    expanded
                    @click="activeStep--"
                  >
                    Back
                  </b-button>
                </div>
                <div class="column is-8">
                  <b-button
                    class="add-new-btn btn-primary-light"
                    expanded
                    @click="activeStep++"
                  >
                    Next
                  </b-button>
                </div>
              </div>
            </b-step-item>
            <b-step-item
              step="3"
              label="Tables"
              :clickable="false"
            >
              <h1 class="subtitle has-text-centered">
                Table names and columns
              </h1>

              <b-field label="Tables">
                <div
                  v-for="(table, index) in importedDatabase.tables"
                  :key="index"
                >
                  <small>Table name</small>
                  <b-input
                    v-model="table.name"
                    type="text"
                    class="mb-3"
                  />
                  <small>Columns</small>
                  <div
                    v-for="(labels, i) in table.columns.labels"
                    :key="i"
                  >
                    <b-input
                      v-for="(label, idx) in labels"
                      :key="idx"
                      v-model="labels[idx]"
                      class="mb-3"
                      type="text"
                      size="is-small"
                    />
                  </div>
                  <hr>
                </div>
              </b-field>

              <div class="columns">
                <div class="column is-4">
                  <b-button
                    expanded
                    @click="activeStep--"
                  >
                    Back
                  </b-button>
                </div>
                <div class="column is-8">
                  <b-button
                    class="add-new-btn btn-primary-light"
                    expanded
                    @click="activeStep++"
                  >
                    Next
                  </b-button>
                </div>
              </div>
            </b-step-item>
            <b-step-item
              step="4"
              label="Confirm"
              :clickable="false"
            >
              <div v-if="!importInProgress">
                <h1 class="subtitle has-text-centered">
                  Start with the data import
                </h1>

                <div class="columns">
                  <div class="column is-4">
                    <b-button
                      expanded
                      @click="activeStep--"
                    >
                      Back
                    </b-button>
                  </div>
                  <div class="column is-8">
                    {{ importFinished }}
                    <b-button
                      class="add-new-btn btn-primary-light"
                      expanded
                      :disabled="!importFinished"
                      @click="startImport()"
                    >
                      Begin the import
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-else />
            </b-step-item>
            <b-step-item
              step="5"
              label="Result"
              :clickable="false"
            >
              <div class="has-text-centered">
                <h1 class="subtitle has-text-centered">
                  Import was successful. You can now use your data in the
                  application.
                </h1>
                <b-icon
                  type="is-success"
                  icon="check-circle"
                  size="is-large"
                />
                <h1 class="subtitle">
                  Success
                </h1>
                <div class="columns">
                  <div class="column is-4">
                    <b-button
                      expanded
                      @click="activeStep--"
                    >
                      Back
                    </b-button>
                  </div>
                  <div class="column is-8">
                    <b-button
                      type="is-success"
                      expanded
                      @click="finishImport()"
                    >
                      Finish
                    </b-button>
                  </div>
                </div>
              </div>
            </b-step-item>
          </b-steps>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { computed, reactive, ref, watch } from '@vue/composition-api';
import AppDatabases from '@/modules/builder/components/application/AppDatabases';
import { createDatabaseService } from '@/services/database-service/databaseRequests';
import { createRecordService } from '@/services/database-service/recordRequests';
import { createTableService } from '@/services/database-service/tableRequests';
import readXlsxFile from 'read-excel-file';
import { uuid } from 'vue-uuid';
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useDatabaseStore } from '@/modules/builder/store/databaseStore';
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import { reactiveResetter } from '@/hooks/utils';
import * as Yup from 'yup';

// compose hooks
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const databaseStore = useDatabaseStore();
  const route = useRoute();
  const router = useRouter();

  // Database Validation
  const DatabaseSchema = Yup.object().shape({
    name: Yup.string().trim().required('Database name is required')
  });
  const databaseErrors = reactive({
    name: ''
  });
  const validateDatabase = async field => {
    try {
      await DatabaseSchema.validateAt(field, database);
      databaseErrors[field] = '';
    } catch (err) {
      databaseErrors[err.path] = err.message;
    }
    // is db name alredy exists
    const isDBNameExists = databaseStore.databases.find(d => d.name.toLowerCase() === database.name.toLowerCase());
    if (isDBNameExists) {
      databaseErrors['name'] = 'Chosen database name exists. Please choose another';
      return false;
    }
  };

  // -- import database logic --//
  const activeStep = ref(0);
  const excelFile = ref({});
  const importInProgress = ref(false);
  const importedDatabaseId = ref(null);
  const importedDatabase = ref({
    name: '',
    tables: []
  });
  const importFinished = ref(true);
  const importDatabaseModalActivity = computed({
    get() {
      return databaseStore.isImportDatabaseModalActive;
    },
    set(value) {
      databaseStore.setImportDatabaseModalActivity(value);
    }
  });
  const parse = () => {
    importedDatabase.value.name = excelFile.value.name;
    readXlsxFile(excelFile.value, {
      getSheets: true
    }).then(sheets => {
      sheets.forEach(sheet => {
        importedDatabase.value.tables.push({
          name: sheet.name,
          columns: []
        });
      });
      importedDatabase.value.tables.forEach((table, index) => {
        readXlsxFile(excelFile.value, {
          sheet: index + 1
        }).then(rows => {
          const labels = rows.slice(0, 1);
          const values = rows.slice(1, rows.length);
          importedDatabase.value.tables[index].columns = {
            labels,
            values
          };
        });
      });
    });
    activeStep.value++;
  };
  const startImport = async () => {
    importFinished.value = false;
    try {
      const createDatabaseResponse = await createDatabaseService({
        name: importedDatabase.value.name,
        application_id: route.params.appId
      });
      if (createDatabaseResponse.status === 201) {
        const databaseId = createDatabaseResponse.data.data.id;
        importedDatabaseId.value = databaseId;
        const defaultColumns = [{
          column_name: 'id',
          column_type: 'UNIQUE',
          default_value: 'UNIQUE VALUE'
        }];
        const tablesToBeCreated = importedDatabase.value.tables.map(table => {
          const tableColumns = table.columns.labels.flatMap(label => label.map(name => ({
            column_name: name,
            column_type: 'TEXT',
            default_value: ''
          })));
          return createTableService(databaseId, {
            database_id: databaseId,
            name: table.name,
            columns: [...defaultColumns, ...tableColumns]
          });
        });
        const createdTableResponses = await Promise.all(tablesToBeCreated);
        importedDatabase.value.tables.forEach((table, index) => {
          const tableId = createdTableResponses[index].data.data.name;
          const records = [];
          let row = {};
          table.columns.values.forEach(value => {
            row = {};
            table.columns.labels.forEach(label => {
              label.forEach((l, i) => {
                row[l] = value[i] || '';
              });
            });
            records.push(row);
          });
          let recordRequest = {
            database: {
              name: databaseId
            },
            table: {
              name: tableId
            },
            records: records
          };
          createRecordService(recordRequest).then(response => {
            if (response.status === 201) {
              activeStep.value++;
            }
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
    importFinished.value = true;
  };
  const finishImport = () => {
    importDatabaseModalActivity.value = false;
    router.push('/application/' + route.params.appId + '/database/' + importedDatabaseId.value);
  };

  // -- create database logic --//
  const newDatabaseModalActivity = computed({
    get() {
      return databaseStore.isNewDatabaseModalActive;
    },
    set(value) {
      databaseStore.setNewDatabaseModalActivity(value);
    }
  });
  const databaseModalActivity = computed({
    get() {
      return databaseStore.isDatabaseModalActive;
    },
    set(value) {
      databaseStore.setDatabaseModalActivity(value);
    }
  });
  const openNewDatabaseModal = () => {
    databaseStore.setDatabaseModalActivity(false);
    databaseStore.setNewDatabaseModalActivity(true);
  };
  const openImportDatabaseModal = () => {
    databaseStore.setDatabaseModalActivity(false);
    databaseStore.setImportDatabaseModalActivity(true);
  };
  const openDatabaseModal = () => {
    databaseStore.setDatabaseModalActivity(true);
  };
  const [database, resetDatabase] = reactiveResetter({
    name: ''
  });
  watch(() => newDatabaseModalActivity.value, () => {
    if (!newDatabaseModalActivity.value) {
      resetDatabase();
    }
  });
  const createDatabase = async () => {
    try {
      await DatabaseSchema.validate(database, {
        abortEarly: false
      });
    } catch (err) {
      err.inner.reverse().forEach(error => {
        databaseErrors[error.path] = error.message;
      });
      return false;
    }

    // is db name alredy exists
    const isDBNameExists = databaseStore.databases.find(d => d.name.toLowerCase() === database.name.trim().toLowerCase());
    if (isDBNameExists) {
      databaseErrors['name'] = 'Database name already exists!';
      return false;
    }
    try {
      const createDatabaseResponse = await createDatabaseService({
        name: database.name.trim(),
        application_id: route.params.appId
      });
      newDatabaseModalActivity.value = false;
      if (createDatabaseResponse.status === 201) {
        router.push('/application/' + route.params.appId + '/database/' + createDatabaseResponse.data.data.id);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return {
    databaseErrors,
    validateDatabase,
    activeStep,
    excelFile,
    importInProgress,
    importedDatabase,
    importFinished,
    importDatabaseModalActivity,
    parse,
    startImport,
    finishImport,
    newDatabaseModalActivity,
    databaseModalActivity,
    openNewDatabaseModal,
    openImportDatabaseModal,
    openDatabaseModal,
    database,
    createDatabase
  };
};
__sfc_main.components = Object.assign({
  AppDatabases,
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.has-light-bg {
  background: $grey-5 !important;
}

.has-insert-shadow {
  box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
}

.card-icon-options {
  height: 60px;
  overflow: hidden;
  border: 1px solid #dedede;
  cursor: pointer;
}

.card-icon-options:hover {
  opacity: 0.5;
}
</style>